<template>
  <v-table
    ref="table"
    path="/cars?populate=group"
    table-sm
    :filter="{ ...filter, search }"
    :fields="carsTableColumns"
  >
    <template v-slot:create-button>
      <div class="col-md-4">
        <b-form-checkbox
          v-model="filter.hide_crm"
          value="1"
          unchecked-value="0"
        >Показать скрытые</b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-button
          variant="primary"
          @click="$emit('create')"
        >
          Добавить
        </b-button>
      </div>
    </template>
    <template #cell(serial)="data">
      <v-table-serial-column
        :data="data"
      />
    </template>
    <template #cell(actions)="data">
      <v-table-actions-column
        @edit="$emit('edit', data.item)"
        @view="$emit('view', data.item)"
        @delete="$emit('delete', data.item)"
      />
    </template>
  </v-table>
</template>

<script>
import VTable, { VTableActionsColumn, VTableSerialColumn } from '@/views/components/vtable'
import { BButton, BFormCheckbox } from 'bootstrap-vue'
import { carsTableColumns } from '../cars_use'

export default {
  components: {
    VTable,
    VTableActionsColumn,
    VTableSerialColumn,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      carsTableColumns,
      filter: {
        group_id__null: 0,
        hide_crm: '0',
      },
    }
  },
  computed: {
    search() {
      return this.$store.getters['app/search']
    },
  },
  methods: {
    refresh() {
      this.$refs.table.refresh()
    },
  },
}
</script>
