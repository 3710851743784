<template>
  <div>
    <b-card>
      <b-tabs>
        <template #tabs-end>
          <b-nav-item
            :active="table === 'default'"
            :to="{name: 'cars'}"
          >С группой</b-nav-item>
          <b-nav-item
            :active="table === 'new'"
            :to="{name: 'cars-new'}"
          >Без группы</b-nav-item>
        </template>
      </b-tabs>
      <car-default
        v-if="table === 'default'"
        ref="content-table"
        @view="carViewHandler"
        @create="carCreateHandler"
        @edit="carEditHandler"
        @delete="carDeleteHandler"
      />
      <car-new
        v-if="table === 'new'"
        ref="content-table"
        @view="carViewHandler"
        @create="carCreateHandler"
        @edit="carEditHandler"
        @delete="carDeleteHandler"
      />
    </b-card>
    <b-modal
      ref="car-modal"
      :title="(carView ? 'Просмотр' : (car.id ? 'Редактировать' : 'Добавить')) + ' авто'"
      size="lg"
      hide-footer
    >
      <object-view
        v-if="carView"
        :obj="fat_to_flat(car)"
        :labels="carViewLabels"
      />
      <car-form
        v-else
        :car="car"
        :cols="2"
        @saved="carSavedHandler"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BModal, BTabs, BNavItem,
} from 'bootstrap-vue'
import ObjectView from '@/views/components/ObjectView.vue'
import CarForm from '@/views/cars/components/CarForm.vue'
import { fat2Flat } from '@/views/utils'
import CarDefault from '@/views/cars/components/CarDefault.vue'
import CarNew from '@/views/cars/components/CarNew.vue'
import { Car, carViewLabels } from './cars_use'

export default {
  components: {
    CarNew,
    CarDefault,
    BCard,
    BModal,
    BTabs,
    BNavItem,
    CarForm,
    ObjectView,
  },
  props: {
    table: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      car: new Car(),
      carView: false,
      carViewLabels,
      long_rental_view: false,
    }
  },
  methods: {
    fat_to_flat: fat2Flat,
    carCreateHandler() {
      this.carView = false
      this.car = new Car()
      this.$refs['car-modal'].show()
    },
    carViewHandler(car) {
      this.$router.push({
        name: 'cars-view',
        params: { id: car.id },
      })
    },
    carEditHandler(car) {
      this.carView = false
      this.car = new Car(car)
      this.$refs['car-modal'].show()
    },
    carDeleteHandler(car) {
      this.$axios.delete(`/cars/${car.id}`).then(() => {
        this.refreshTable()
      }).catch(() => {
        this.$bvToast.toast('Не удалось удалить!', {
          variant: 'danger',
          title: 'Ошибка',
        })
      })
    },
    carSavedHandler() {
      this.$refs['car-modal'].hide()
      this.refreshTable()
    },
    refreshTable() {
      this.$refs['content-table'].refresh()
    },
  },
}
</script>
