<template>
  <div>
    <b-row
      v-for="(value, label, index) in viewData"
      :key="label"
      :class="{'pt-1': index > 0}"
    >
      <b-col
        :cols="labelCols"
      >
        <strong>{{ label.replace('_', ' ') | titleCase }}</strong>
      </b-col>
      <b-col
        :cols="valueCols"
      >{{ value }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      default: null,
    },
    labelCols: {
      type: Number,
      default: 4,
    },
    valueCols: {
      type: Number,
      default: 8,
    },
  },
  computed: {
    viewData() {
      if (this.labels === null) {
        return this.obj
      }
      const result = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, label] of Object.entries(this.labels)) {
        if (Object.hasOwn(this.obj, key)) {
          result[label] = this.obj[key]
        }
      }
      return result
    },
  },
}
</script>
